// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getFlexiComboPriview, updateFlexiCombo } from '@/services/promotion.js'
import { getShopsSimpleList } from '@/services/shops.js'

// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'
import moment from 'moment'

export default {
  name: 'flexiComboEdit',
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData,
      disableArry: [
        'name',
        'begin_time_end_time',
        'terms_and_conditions',
        'details',
        'shop_id',
        'deal_criteria',
        'discount_type',
        'stackable_discount',
        'item_quantity_tiers_attributes',
        'item_value_tiers_attributes'
      ],
      groupTypeOptions: [
        {
          label: this.$t('form.common.retail'),
          value: 'retail'
        },
        {
          label: this.$t('form.common.wholesale'),
          value: 'wholesale'
        }
      ]
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      this.formData[0].createDateItem[5].options = this.groupTypeOptions
      // getFlexiComboDiscountType().then(res => {
      //   if (res.status === 200) {
      //     this.formData[0].createDateItem[7].options = res.data
      //     this.formData[0].createDateItem[7].value = res.data[0].value
      //   }
      // })
      getShopsSimpleList().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[3].options = []
          res.data.shops.forEach((item) => {
            const obj = {
              value: item.id,
              name: item.name
            }
            this.formData[0].createDateItem[3].options.push(obj)
          })
        }
      })
      getFlexiComboPriview(this.$route.params.id).then(res => {
        if (res.status === 200) {
          let { begin_time, end_time, status } = res.data.flexi_combo
          begin_time = moment(begin_time).format('yyyy-MM-DD HH:mm')
          end_time = moment(end_time).format('yyyy-MM-DD HH:mm')
          res.data.flexi_combo.begin_time_end_time = [begin_time, end_time]
          // res.data.flexi_combo.discount_attributes = [{
          //   discount_amount_f,
          //   discount_percent,
          //   required_quantity
          // }]
          const length1 = res.data.flexi_combo.item_quantity_tiers_attributes.length
          const length2 = res.data.flexi_combo.item_value_tiers_attribtues ? res.data.flexi_combo.item_value_tiers_attribtues.length : 0
          for (let i = 0; i < length1; i++) {
            if (i < length1 - 1) {
              res.data.flexi_combo.item_quantity_tiers_attributes[i].disabled = true
            }
          }
          for (let i = 0; i < length2; i++) {
            if (i < length2 - 1) {
              res.data.flexi_combo.item_value_tiers_attribtues[i].disabled = true
            }
          }
          this.modifyData = res.data.flexi_combo
          if (status.toLowerCase() === 'ongoing' || status === '进行中') {
            this.formData[0].createDateItem.forEach((item) => {
              console.log('----item---', item)
              if (this.disableArry.includes(item.prop)) {
                item.Object.disabled = true
              }
            })

            this.formData[0].createDateItem[9].Object.addFirstDisabled = true
            this.formData[0].createDateItem[8].Object.addFirstDisabled = true
          }
          console.log(this.modifyData)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleChange (data, prop) {
      // if (prop === 'poi_ids') {
      //   this.getProduct(data)
      // }
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      // const { discount_amount_f, discount_percent, required_quantity } = obj.discount_attributes[0]
      // obj.begin_time = obj.begin_time_end_time[0]
      // obj.end_time = obj.begin_time_end_time[1]
      // delete obj.discount_attributes
      // delete obj.begin_time_end_time
      // const params = {
      //   flexi_combo: {
      //     ...obj,
      //     discount_amount_f,
      //     discount_percent,
      //     required_quantity
      //   }
      // }
      const type = data.discount_type
      obj.item_quantity_tiers_attributes.forEach((item) => {
        item.discount_type = type
        delete item.disabled
      })
      obj.item_value_tiers_attributes.forEach((item) => {
        item.discount_type = type
        delete item.disabled
      })
      obj.begin_time_end_time[0] = moment(obj.begin_time_end_time[0]).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      obj.begin_time_end_time[1] = moment(obj.begin_time_end_time[1]).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      const params = {
        flexi_combo: {
          ...obj
        }
      }
      updateFlexiCombo(this.$route.params.id, params).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'flexiComboList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    afterAdd (creatData, alterData, prop) {
      const length = creatData[prop.prop].length
      const item = creatData[prop.prop][length - 1]
      console.log(creatData)
      if (creatData.deal_criteria === 'item_quantity') {
        alterData.required_items = item ? Number(item.required_items) + 1 : 10
      } else {
        alterData.required_value_f = item ? Number(item.required_value_f) + 1 : 1000
      }
      alterData.discount_value_f = item ? Number(item.discount_value_f) + 1 : 100
      alterData.discount_percent = item ? Number(item.discount_percent) + 1 : 10
      if (item) {
        creatData[prop.prop][length - 1].disabled = true
      }
      creatData[prop.prop].push(alterData)
      this.handleSwitchChange(creatData.stackable_discount, creatData)
      this.checkDisable(creatData)
    },
    removeArray (createData, obj) {
      if (obj.index - 1 > -1) {
        delete createData[obj.prop][obj.index - 1].disabled
      }
      console.log('--removeArray-----', createData[obj.prop])
      if (createData[obj.prop][obj.index].id) {
        this.$set(createData[obj.prop][obj.index], obj.mark, true)
        // createData[obj.prop][obj.index][obj.mark] = createData[obj.prop][obj.index].id
      } else {
        createData[obj.prop].splice(obj.index, 1)
      }
      this.checkDisable(createData)
    },
    checkDisable (createData) {
      if (createData.discount_type === 'money_value_off' && createData.stackable_discount) {
        if (createData.item_value_tiers_attributes.length > 0) {
          this.formData[0].createDateItem[9].Object.addFirstDisabled = true
        } else {
          this.formData[0].createDateItem[9].Object.addFirstDisabled = false
        }
        if (createData.item_quantity_tiers_attributes.length > 0) {
          this.formData[0].createDateItem[8].Object.addFirstDisabled = true
        } else {
          this.formData[0].createDateItem[8].Object.addFirstDisabled = false
        }
      }
    },
    handleSwitchChange (value, createDate, prop) {
      if (prop === 'stackable_discount') {
        console.log('----prop----', prop)
        if (value && createDate.item_value_tiers_attributes.length > 0) {
          this.formData[0].createDateItem[9].Object.addFirstDisabled = true
          createDate.item_value_tiers_attributes = createDate.item_value_tiers_attributes.slice(0, 1) || []
          delete createDate.item_value_tiers_attributes[0].disabled
        }
        if (!value || createDate.item_value_tiers_attributes.length < 1) {
          this.formData[0].createDateItem[9].Object.addFirstDisabled = false
        }
        if (value && createDate.item_quantity_tiers_attributes.length > 0) {
          this.formData[0].createDateItem[8].Object.addFirstDisabled = true
          createDate.item_quantity_tiers_attributes = createDate.item_quantity_tiers_attributes.slice(0, 1) || []
          delete createDate.item_quantity_tiers_attributes[0].disabled
        }
        if (!value || createDate.item_quantity_tiers_attributes.length < 1) {
          this.formData[0].createDateItem[8].Object.addFirstDisabled = false
        }
      }
      if (prop === 'entire_shop') {
        if (value) {
          this.btnTxt = this.$t('button.confirm')
        } else {
          this.btnTxt = this.$t('button.next')
        }
      }
    }
  }
}
