import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: i18n.t('form.store.generalInformation'),
    name: 0,
    createDateItem: [{
      //   span: 24,
      //   prop: 'online',
      //   value: false,
      //   name: 'switch',
      //   label: i18n.t('form.coupon.online'),
      //   Object: {}
      // }, {
      span: 12,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.flexiCombo.name'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 12,
      prop: 'begin_time_end_time',
      value: [],
      name: 'date',
      type: 'datetimerange',
      label: i18n.t('form.flexiCombo.period'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        'start-placeholder': i18n.t('form.flexiCombo.startDate'),
        'end-placeholder': i18n.t('form.flexiCombo.endDate'),
        delete: '_destroy',
        format: 'yyyy-MM-dd HH:mm',
        'value-format': 'yyyy-MM-dd HH:mm'
      }
    }, {
      span: 24,
      prop: 'terms_and_conditions',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.flexiCombo.termsAndConditions'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
    //   span: 24,
    //   prop: 'details',
    //   name: 'input',
    //   type: 'textarea',
    //   label: i18n.t('form.flexiCombo.details'),
    //   placeholder: i18n.t('placeholder.defaultInput'),
    //   Object: {
    //   }
    // }, {
      span: 12,
      prop: 'shop_id',
      name: 'select',
      options: [],
      label: i18n.t('form.common.shop'),
      Object: {
        // key: 'id'
      }
    }, {
      span: 12,
      prop: 'max_orders_count',
      name: 'input',
      type: 'number',
      label: i18n.t('form.flexiCombo.total_number'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 12,
      prop: 'combo_type',
      name: 'select',
      options: [],
      label: i18n.t('form.common.userType'),
      Object: {
        // key: 'id'
        disabled: true
      }
    }, {
      span: 24,
      prop: 'deal_criteria',
      name: 'group-radio',
      value: 'item_quantity',
      options: [{
        name: i18n.t('form.flexiCombo.item_quantity'),
        value: 'item_quantity'
      }, {
        name: i18n.t('form.flexiCombo.item_value'),
        value: 'item_value'
      }],
      label: i18n.t('form.flexiCombo.deal_criteria'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        nextChange: true,
        nextChangeProp: 'deal_criteria'
      }
    }, {
      span: 24,
      prop: 'discount_type',
      name: 'group-radio',
      value: 'percent_off',
      options: [
        {
          name: i18n.t('form.flexiCombo.percent_off'),
          value: 'percent_off'
        },
        {
          name: i18n.t('form.flexiCombo.money_value_off'),
          value: 'money_value_off'
        }
      ],
      label: i18n.t('form.flexiCombo.discount_type'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        nextChange: true,
        nextChangeProp: 'discount_type'
      }
    }, {
      span: 12,
      prop: 'stackable_discount',
      value: false,
      name: 'switch',
      label: i18n.t('form.flexiCombo.stackableDiscount'),
      Object: {
        visible: 'discount_type',
        visibleValue: 'money_value_off',
        bottomNotice: i18n.t('form.flexiCombo.stackable')
      }
    }, {
      span: 24,
      prop: 'item_quantity_tiers_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.flexiCombo.discountDetails'),
      Object: {
        delete: '_destroy',
        lastVisible: true,
        visible: 'deal_criteria',
        visibleValue: 'item_quantity',
        addFirstDisabled: false
      },
      createDateFirstItem: [{
        span: 12,
        prop: 'required_items',
        name: 'input',
        type: 'number',
        label: i18n.t('form.flexiCombo.requierQuantity'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
          // visible: 'deal_criteria',
          // visibleValue: 'item_quantity'
        }
      }, {
        span: 12,
        prop: 'discount_value_f',
        name: 'input',
        type: 'number',
        label: i18n.t('form.flexiCombo.discountAmount'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
          visible: 'discount_type',
          visibleValue: 'money_value_off',
          appendVisible: true,
          append: 'SGD'
        }
      }, {
        span: 12,
        prop: 'discount_percent',
        name: 'input',
        type: 'number',
        label: i18n.t('form.flexiCombo.discountAmount'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
          visible: 'discount_type',
          visibleValue: 'percent_off',
          appendVisible: true,
          append: '% off'
        }
      }]
    }, {
      span: 24,
      prop: 'item_value_tiers_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.flexiCombo.discountDetails'),
      Object: {
        delete: '_destroy',
        lastVisible: true,
        visible: 'deal_criteria',
        visibleValue: 'item_value',
        addFirstDisabled: false
      },
      createDateFirstItem: [{
        span: 12,
        prop: 'required_value_f',
        name: 'input',
        type: 'number',
        label: i18n.t('form.flexiCombo.requierValue'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
          // visible: 'deal_criteria',
          // visibleValue: 'item_value'
        }
      }, {
        span: 12,
        prop: 'discount_value_f',
        name: 'input',
        type: 'number',
        label: i18n.t('form.flexiCombo.discountAmount'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
          visible: 'discount_type',
          visibleValue: 'money_value_off',
          appendVisible: true,
          append: 'SGD'
        }
      }, {
        span: 12,
        prop: 'discount_percent',
        name: 'input',
        type: 'number',
        label: i18n.t('form.flexiCombo.discountAmount'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
          visible: 'discount_type',
          visibleValue: 'percent_off',
          appendVisible: true,
          append: '% off'
        }
      }]
    }, {
      span: 24,
      prop: 'entire_shop',
      name: 'switch',
      value: false,
      label: i18n.t('form.flexiCombo.discountApply'),
      Object: {
        inactiveNotice: i18n.t('form.flexiCombo.selectProduct'),
        disabled: true
      }
    }]
  }]
}
